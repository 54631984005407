exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amenities-cn-js": () => import("./../../../src/pages/amenities.cn.js" /* webpackChunkName: "component---src-pages-amenities-cn-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-contact-cn-js": () => import("./../../../src/pages/contact.cn.js" /* webpackChunkName: "component---src-pages-contact-cn-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contactcn-cn-js": () => import("./../../../src/pages/contactcn.cn.js" /* webpackChunkName: "component---src-pages-contactcn-cn-js" */),
  "component---src-pages-contactcn-js": () => import("./../../../src/pages/contactcn.js" /* webpackChunkName: "component---src-pages-contactcn-js" */),
  "component---src-pages-contacthk-hk-js": () => import("./../../../src/pages/contacthk.hk.js" /* webpackChunkName: "component---src-pages-contacthk-hk-js" */),
  "component---src-pages-contacthk-js": () => import("./../../../src/pages/contacthk.js" /* webpackChunkName: "component---src-pages-contacthk-js" */),
  "component---src-pages-gallery-cn-js": () => import("./../../../src/pages/gallery.cn.js" /* webpackChunkName: "component---src-pages-gallery-cn-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-cn-js": () => import("./../../../src/pages/index.cn.js" /* webpackChunkName: "component---src-pages-index-cn-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiors-cn-js": () => import("./../../../src/pages/interiors.cn.js" /* webpackChunkName: "component---src-pages-interiors-cn-js" */),
  "component---src-pages-interiors-js": () => import("./../../../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-plans-cn-js": () => import("./../../../src/pages/plans.cn.js" /* webpackChunkName: "component---src-pages-plans-cn-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-register-cn-js": () => import("./../../../src/pages/register.cn.js" /* webpackChunkName: "component---src-pages-register-cn-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-walk-in-cn-js": () => import("./../../../src/pages/register-walk-in.cn.js" /* webpackChunkName: "component---src-pages-register-walk-in-cn-js" */),
  "component---src-pages-register-walk-in-js": () => import("./../../../src/pages/register-walk-in.js" /* webpackChunkName: "component---src-pages-register-walk-in-js" */),
  "component---src-pages-thankyou-cn-js": () => import("./../../../src/pages/thankyou.cn.js" /* webpackChunkName: "component---src-pages-thankyou-cn-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-views-cn-js": () => import("./../../../src/pages/views.cn.js" /* webpackChunkName: "component---src-pages-views-cn-js" */),
  "component---src-pages-views-js": () => import("./../../../src/pages/views.js" /* webpackChunkName: "component---src-pages-views-js" */),
  "component---src-pages-views-low-cn-js": () => import("./../../../src/pages/views/low.cn.js" /* webpackChunkName: "component---src-pages-views-low-cn-js" */),
  "component---src-pages-views-low-js": () => import("./../../../src/pages/views/low.js" /* webpackChunkName: "component---src-pages-views-low-js" */),
  "component---src-pages-views-mid-cn-js": () => import("./../../../src/pages/views/mid.cn.js" /* webpackChunkName: "component---src-pages-views-mid-cn-js" */),
  "component---src-pages-views-mid-js": () => import("./../../../src/pages/views/mid.js" /* webpackChunkName: "component---src-pages-views-mid-js" */),
  "component---src-pages-views-upper-cn-js": () => import("./../../../src/pages/views/upper.cn.js" /* webpackChunkName: "component---src-pages-views-upper-cn-js" */),
  "component---src-pages-views-upper-js": () => import("./../../../src/pages/views/upper.js" /* webpackChunkName: "component---src-pages-views-upper-js" */)
}

